import openSocket from "socket.io-client";
import { isObject } from "lodash";

export function socketConnection(params) {
  // Verificar se params é um objeto, se não, usar o userId do localStorage.
  let userId = localStorage.getItem("userId") || null;

  // Se params for um objeto, usa seus valores; caso contrário, utiliza userId do localStorage ou de params.
  const queryParams = isObject(params) ? { ...params } : { userId: userId || params.userId };

  return openSocket(process.env.REACT_APP_BACKEND_URL, {
    transports: ["websocket", "polling", "flashsocket"],
    pingTimeout: 18000,
    pingInterval: 18000,
    query: queryParams,
  });
}