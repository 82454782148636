import * as Yup from 'yup';
import checkoutFormModel from './checkoutFormModel';

const {
  formField: {
    firstName,
    address1,
    city,
    zipcode,
    country,
  }
} = checkoutFormModel;

const validationSchema = Yup.object().shape({
  [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
  [address1.name]: Yup.string().required(`${address1.requiredErrorMsg}`),
  [city.name]: Yup.string()
    .required(`${city.requiredErrorMsg}`),
  [zipcode.name]: Yup.string()
    .required(`${zipcode.requiredErrorMsg}`),
  [country.name]: Yup.string()
    .required(`${country.requiredErrorMsg}`)
});

const validationSchemasArray = [validationSchema];

export default validationSchemasArray;
