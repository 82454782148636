import React from "react";
import ReactDOM from "react-dom/client";
import CssBaseline from "@material-ui/core/CssBaseline";
import App from "./App";

// Seleciona o elemento root
const rootElement = document.getElementById("root");

// Cria um root para renderização
const root = ReactDOM.createRoot(rootElement);

// Renderiza o conteúdo utilizando a nova API
root.render(
	 //<React.StrictMode> { /*antes de descomentar migrar aplicação antes para mui@matrerial pois kanban usa biblioteca legada.*/}
		<CssBaseline>
			<App />
		</CssBaseline>
	//</React.StrictMode>
);