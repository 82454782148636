import axios from "axios";
import { useState } from "react";

// Centralizar a baseURL para evitar repetição de código
const baseApiUrl = process.env.REACT_APP_BACKEND_URL;

// Função para criar uma instância de axios com ou sem credenciais
const createApiInstance = (withCredentials = false) => {
  return axios.create({
    baseURL: baseApiUrl,
    withCredentials: withCredentials,
  });
};

// Instância da API com credenciais (para autenticação)
const api = createApiInstance(true);

// Instância da API pública sem credenciais
export const openApi = createApiInstance(false);

// Componente React para gerenciar o estado do erro de autenticação
const AuthErrorComponent = () => {
  const [authError, setAuthError] = useState(false); // Estado para controlar o erro de autenticação

  // Adicionando interceptores de resposta para tratamento global de erros
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // Tratamento de erros globais, como erros de autenticação
      if (error.response && error.response.status === 401) {
        setAuthError(true); // Define o erro de autenticação no estado
        alert("Sessão expirada, por favor faça o login novamente."); // Alerta o usuário

        // Espera 5 segundos antes de redirecionar para a página de login
        setTimeout(() => {
          window.location.href = '/login';
        }, 5000); // 5 segundos de atraso

      } else if (error.response && error.response.status === 500) {
        console.error("Erro no servidor: ", error.response.data);
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      {/* Exibe a mensagem de erro se houver um erro de autenticação */}
      {authError && <div className="auth-error">Sessão expirada, por favor faça o login novamente.</div>}
    </>
  );
};

export default api;
export { AuthErrorComponent };