import React, { useState, useEffect, useCallback, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import MinimizeIcon from '@material-ui/icons/Minimize';
import AddIcon from '@material-ui/icons/Add';
import usePlans from "../../../hooks/usePlans";
import useCompanies from "../../../hooks/useCompanies";
import { AuthContext } from '../../../context/Auth/AuthContext'; // Verifique se este caminho está correto

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },

  customCard: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    flexDirection: "column",
  },
  custom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  loadingIndicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  }
}));

export default function Pricing(props) {
  const {
    setFieldValue,
    setActiveStep,
    activeStep,
  } = props;

  const { finder } = usePlans();
  const { find } = useCompanies();
  const { user } = useContext(AuthContext); // Usando o AuthContext para acessar o user

  const classes = useStyles();
  const [usersPlans, setUsersPlans] = useState(3);
  const [connectionsPlans, setConnectionsPlans] = useState(3);
  const [storagePlans, setStoragePlans] = useState([]);
  const [customValuePlans, setCustomValuePlans] = useState(49.00);
  const [loading, setLoading] = useState(true);

  // Extrai o companyId do objeto user
  const companyId = user?.companyId;

  const handleChangeMin = () => {
    if (usersPlans > 3) {
      setUsersPlans(usersPlans - 1);
      setCustomValuePlans(customValuePlans - 11.00);
    }
  };

  const handleChangeAdd = () => {
    setUsersPlans(usersPlans + 1);
    setCustomValuePlans(customValuePlans + 11.00);
  };

  const handleChangeConnectionsMin = () => {
    if (connectionsPlans > 3) {
      setConnectionsPlans(connectionsPlans - 1);
      setCustomValuePlans(customValuePlans - 20.00);
    }
  };

  const handleChangeConnectionsAdd = () => {
    setConnectionsPlans(connectionsPlans + 1);
    setCustomValuePlans(customValuePlans + 20.00);
  };

  const loadPlans = useCallback(async (companiesPlans) => {
    try {
      const plansCompanies = await finder(companiesPlans);
      if (plansCompanies) {
        const plans = [{
          title: plansCompanies.name,
          planId: plansCompanies.id,
          price: plansCompanies.value,
          description: [
            `${plansCompanies.users} Usuários`,
            `${plansCompanies.connections} Conexões`,
            `${plansCompanies.queues} Filas`
          ],
          users: plansCompanies.users,
          connections: plansCompanies.connections,
          queues: plansCompanies.queues,
          buttonText: 'SELECIONAR',
          buttonVariant: 'outlined',
          custom: plansCompanies.custom || false,
        }];
        setStoragePlans(plans);
      } else {
        console.error("Planos não encontrados.");
      }
    } catch (e) {
      console.log(e);
      // toast.error("Não foi possível carregar a lista de registros");
    }
  }, [finder]);

  const loadCompanies = useCallback(async () => {
    if (!companyId) {
      console.error("companyId não encontrado no AuthContext.");
      return;
    }

    setLoading(true);
    try {
      const companiesList = await find(companyId);
      if (companiesList && companiesList.planId) {
        await loadPlans(companiesList.planId);
      } else {
        console.error("Empresa ou plano não encontrados.");
      }
    } catch (e) {
      console.log(e);
      // toast.error("Não foi possível carregar a lista de registros");
    }
    setLoading(false);
  }, [companyId, find, loadPlans]);

  useEffect(() => {
    if (companyId) {
      loadCompanies();
    }
  }, [companyId, loadCompanies]);

  if (loading) {
    return (
      <div className={classes.loadingIndicator}>
        <CircularProgress />
      </div>
    );
  }

  const handlePlanSelection = (tier) => {
    try {
      let selectedPlan;
      if (tier.custom) {
        selectedPlan = {
          users: usersPlans,
          connections: connectionsPlans,
          price: customValuePlans,
        };
      } else {
        selectedPlan = tier;
      }
      setFieldValue("plan", JSON.stringify(selectedPlan));
      setActiveStep(activeStep + 1);
    } catch (error) {
      console.error("Erro ao selecionar plano:", error);
    }
  };

  const tiers = storagePlans;
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {tiers.map((tier) => (
          <Grid item key={tier.title} xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                title={tier.title}
                subheader={tier.subheader}
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{ align: 'center' }}
                action={tier.title === 'Pro' ? <StarIcon /> : null}
                className={classes.cardHeader}
              />
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography component="h2" variant="h3" color="textPrimary">
                    R${tier.price.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    /mês
                  </Typography>
                </div>
                <ul>
                  {tier.description.map((line) => (
                    <Typography component="li" variant="subtitle1" align="center" key={line}>
                      {line}
                    </Typography>
                  ))}
                </ul>
                {tier.custom && (
                  <div>
                    <div className={classes.custom}>
                      <IconButton onClick={handleChangeMin} className={classes.margin}>
                        <MinimizeIcon />
                      </IconButton>
                      <Typography variant="h6">
                        Usuários: {usersPlans}
                      </Typography>
                      <IconButton onClick={handleChangeAdd} className={classes.margin}>
                        <AddIcon />
                      </IconButton>
                    </div>
                    <div className={classes.custom}>
                      <IconButton onClick={handleChangeConnectionsMin} className={classes.margin}>
                        <MinimizeIcon />
                      </IconButton>
                      <Typography variant="h6">
                        Conexões: {connectionsPlans}
                      </Typography>
                      <IconButton onClick={handleChangeConnectionsAdd} className={classes.margin}>
                        <AddIcon />
                      </IconButton>
                    </div>
                    <Typography variant="h6" align="center">
                      Preço: R${customValuePlans.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                    </Typography>
                  </div>
                )}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  color="primary"
                  onClick={() => handlePlanSelection(tier)}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}