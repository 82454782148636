import React, { useState, useEffect, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import SubscriptionModal from "../../components/SubscriptionModal";
import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import toastError from "../../errors/toastError";
import moment from "moment";

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_INVOICES":
      const invoices = action.payload;
      const newUsers = [];

      invoices.forEach((user) => {
        const userIndex = state.findIndex((u) => u.id === user.id);
        if (userIndex !== -1) {
          state[userIndex] = user;
        } else {
          newUsers.push(user);
        }
      });

      return [...state, ...newUsers];

    case "UPDATE_USERS":
      const user = action.payload;
      const userIndex = state.findIndex((u) => u.id === user.id);
      if (userIndex !== -1) {
        state[userIndex] = user;
        return [...state];
      } else {
        return [user, ...state];
      }

    case "DELETE_USER":
      return state.filter((u) => u.id !== action.payload);

    case "RESET":
      return [];

    default:
      return state;
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const Invoices = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [invoices, dispatch] = useReducer(reducer, []);
  const [storagePlans, setStoragePlans] = useState([]);
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const handleOpenContactModal = (invoices) => {
    setStoragePlans(invoices);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setContactModalOpen(false);
  };

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, []);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchInvoices = async () => {
        try {
          const { data } = await api.get("/invoices/all", {
            params: { pageNumber },
          });
          dispatch({ type: "LOAD_INVOICES", payload: data });
          setHasMore(data.hasMore);
        } catch (err) {
          toastError(err);
        } finally {
          setLoading(false);
        }
      };
      fetchInvoices();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [pageNumber]);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop - 100 < clientHeight) {
      loadMore();
    }
  };

  const rowStyle = (record) => {
    const hoje = moment().format("DD/MM/yyyy");
    const vencimento = moment(record.dueDate).format("DD/MM/yyyy");
    const dias = moment(vencimento, "DD/MM/yyyy").diff(moment(hoje, "DD/MM/yyyy"), 'days');

    return dias < 0 && record.status !== "paid" ? { backgroundColor: "#ffbcbc9c" } : {};
  };

  const rowStatus = (record) => {
    const hoje = moment().format("DD/MM/yyyy");
    const vencimento = moment(record.dueDate).format("DD/MM/yyyy");
    const dias = moment(vencimento, "DD/MM/yyyy").diff(moment(hoje, "DD/MM/yyyy"), 'days');

    return record.status === "paid" ? "Pago" : dias < 0 ? "Vencido" : "Em Aberto";
  };

  return (
    <MainContainer>
      <SubscriptionModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        Invoice={storagePlans}
      />
      <MainHeader>
        <Title>Faturas</Title>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined" onScroll={handleScroll}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Id</TableCell>
              <TableCell align="center">Detalhes</TableCell>
              <TableCell align="center">Valor</TableCell>
              <TableCell align="center">Data Venc.</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((invoice) => (
              <TableRow style={rowStyle(invoice)} key={invoice.id}>
                <TableCell align="center">{invoice.id}</TableCell>
                <TableCell align="center">{invoice.detail}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="center">
                  {invoice.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </TableCell>
                <TableCell align="center">{moment(invoice.dueDate).format("DD/MM/YYYY")}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="center">
                  {rowStatus(invoice)}
                </TableCell>
                <TableCell align="center">
                  {rowStatus(invoice) !== "Pago" ? (
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleOpenContactModal(invoice)}
                    >
                      PAGAR
                    </Button>
                  ) : (
                    <Button size="small" variant="outlined" disabled>
                      PAGO
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {loading && <TableRowSkeleton columns={4} />}
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Invoices;