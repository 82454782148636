import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { format, isSameDay, parseISO } from "date-fns";
import { useHistory, useParams } from "react-router-dom";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Typography,
  Avatar,
  Divider,
  Badge,
  Box,
  Tooltip,
} from "@material-ui/core";
import { blue, green, grey, orange, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import AndroidIcon from "@material-ui/icons/Android";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ContactTag from "../ContactTag";
import TicketMessagesDialog from "../TicketMessagesDialog";

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
  },
  pendingTicket: {
    cursor: "unset",
  },
  queueTag: {
    background: "#FCFCFC",
    color: "#000",
    marginRight: 1,
    padding: 1,
    fontWeight: "bold",
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 3,
    fontSize: "0.8em",
    whiteSpace: "nowrap",
  },
  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  newMessagesCount: {
    position: "absolute",
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto",
    top: "10px",
    left: "20px",
    borderRadius: 0,
  },
  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },
  connectionTag: {
    background: "green",
    color: "#FFF",
    marginRight: 1,
    padding: 1,
    fontWeight: "bold",
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 3,
    fontSize: "0.8em",
    whiteSpace: "nowrap",
  },
  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },
  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "5px",
  },
  lastMessageTime: {
    justifySelf: "flex-end",
    textAlign: "right",
    position: "relative",
    top: -21,
    background: "#333333",
    color: "#ffffff",
    border: "1px solid #3a3b6c",
    borderRadius: 5,
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: "0.9em",
  },
  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },
  contactLastMessage: {
    paddingRight: "0%",
    marginLeft: "5px",
  },
  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
  },
  acceptButton: {
    position: "absolute",
    left: "50%",
    marginRight: theme.spacing(1),
  },
  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },
  ticketInfo: {
    position: "relative",
    top: -13,
  },
  secondaryContentSecond: {
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "flex-start",
  },
  ticketInfo1: {
    position: "relative",
    top: 13,
    right: 0,
  },
  Radiusdot: {
    "& .MuiBadge-badge": {
      borderRadius: 2,
      position: "inherit",
      height: 16,
      margin: 2,
      padding: 3,
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(0%, -40%)",
    },
  },
}));

const TicketListItemCustom = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [ticketUser, setTicketUser] = useState(null);
  const [ticketQueueName, setTicketQueueName] = useState(null);
  const [ticketQueueColor, setTicketQueueColor] = useState(null);
  const [tag, setTag] = useState([]);
  const [whatsAppName, setWhatsAppName] = useState(null);
  const [lastInteractionLabel, setLastInteractionLabel] = useState({ labelText: "", labelColor: "" });
  const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { setCurrentTicket } = useContext(TicketsContext);
  const { user } = useContext(AuthContext);
  const { profile } = user;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (ticket.userId && ticket.user) {
      setTicketUser(ticket.user.name.toUpperCase());
    }
    setTicketQueueName(ticket.queue?.name?.toUpperCase() || "SEM FILA");
    setTicketQueueColor(ticket.queue?.color || "#7C7C7C");

    if (ticket.whatsappId && ticket.whatsapp) {
      setWhatsAppName(ticket.whatsapp.name.toUpperCase());
    }
    setTag(ticket?.tags);
  }, [ticket]);

  useEffect(() => {
    const renderLastInteractionLabel = () => {
      if (!ticket.updatedAt) {
        return { labelText: "Nenhuma interação recente", labelColor: "grey" };
      }

      const lastInteractionDate = parseISO(ticket.updatedAt);
      const currentDate = new Date();
      const timeDifference = currentDate - lastInteractionDate;
      const minutesDifference = Math.floor(timeDifference / (1000 * 60));
      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      let labelText = "";
      let labelColor = "";

      if (minutesDifference < 3) {
        labelText = "Agora mesmo";
        labelColor = green[500];
      } else if (minutesDifference >= 3 && minutesDifference < 10) {
        labelText = `(${minutesDifference} m atrás)`;
        labelColor = orange[500];
      } else if (minutesDifference >= 10 && minutesDifference < 60) {
        labelText = `(${minutesDifference} m atrás)`;
        labelColor = red[500];
      } else if (hoursDifference >= 1 && hoursDifference < 24) {
        labelText = `(${hoursDifference} h atrás)`;
        labelColor = red[500];
      } else if (daysDifference >= 1) {
        labelText = `(${daysDifference} dias atrás)`;
        labelColor = red[500];
      } //console.log(labelText, labelColor, format(parseISO(ticket.updatedAt), "HH:mm"), "Atualização da Hora.");

      return { labelText, labelColor };
    };

    const updateLastInteractionLabel = () => {
      const { labelText, labelColor } = renderLastInteractionLabel();
      setLastInteractionLabel({ labelText, labelColor });
    };
    updateLastInteractionLabel(); // Atualização inicial

    // Intervalo para forçar a atualização a cada 30 segundos
    const intervalId = setInterval(() => {
      updateLastInteractionLabel();
    }, 30 * 1000);

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente é desmontado
  }, [ticket.updatedAt, ticket.lastMessage, ticket]);

  const handleCloseTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "closed",
        userId: user?.id,
        queueId: ticket?.queue?.id,
        useIntegration: false,
        promptId: null,
        integrationId: null,
      });
      history.push(`/tickets/`);
    } catch (err) {
      toastError(err);
    } finally {
      isMounted.current && setLoading(false);
    }
  };

  const handleReopenTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
        queueId: ticket?.queue?.id,
      });
    } catch (err) {
      toastError(err);
    } finally {
      isMounted.current && setLoading(false);
    }
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleAcceptTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      });

      // Verificar a configuração e enviar mensagem de saudação se habilitado																			 
      const { data } = await api.get("/settings/");
      const greetingSetting = data.find((s) => s.key === "sendGreetingAccepted");

      if (greetingSetting?.value === "enabled" && !ticket.isGroup) {
        handleSendMessage(ticket.id);
      }
    } catch (err) {
      toastError(err);
    } finally {
      isMounted.current && setLoading(false);
    }
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleSendMessage = async (id) => {
    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: `*Mensagem Automática:*\n{{ms}} *{{name}}*, meu nome é *${user?.name}* e agora vou prosseguir com seu atendimento!`,
    };
    try {
      await api.post(`/messages/${id}`, message);
    } catch (err) {
      toastError(err);
    }
  };

  const handleSelectTicket = (ticket) => {
    const code = uuidv4();
    const { id, uuid } = ticket;
    setCurrentTicket({ id, uuid, code });
  };

  const renderTicketInfo = () => (
    <>
      {ticket.chatbot && (
        <Tooltip title="Chatbot">
          <AndroidIcon fontSize="small" style={{ color: grey[700], marginRight: 5 }} />
        </Tooltip>
      )}
    </>
  );

  return (
    <React.Fragment key={ticket.id}>
      <TicketMessagesDialog
        open={openTicketMessageDialog}
        handleClose={() => setOpenTicketMessageDialog(false)}
        ticketId={ticket.id}
      />
      <ListItem dense button
        onClick={(e) => {
          if (ticket.status === "pending") return;
          handleSelectTicket(ticket);
        }}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >
        <Tooltip arrow placement="right" title={ticketQueueName || "SEM FILA"}>
          <span style={{ backgroundColor: ticketQueueColor }} className={classes.ticketQueueColor}></span>
        </Tooltip>
        <ListItemAvatar>
          <Avatar
            style={{
              marginTop: ticket.status !== "pending" ? "-20px" : "-30px",
              marginLeft: ticket.status !== "pending" ? "-3px" : "0px",
              width: "50px",
              height: "50px",
              borderRadius: "10%",
            }}
            src={ticket?.contact?.profilePicUrl}
          />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <span className={classes.contactNameWrapper}>
              <Typography
                noWrap
                component='span'
                variant='body2'
                color='textPrimary'
              >
                <strong>{ticket.contact.name} </strong>
                {lastInteractionLabel && (
                  <Badge
                    className={classes.lastInteractionLabel}
                    style={{ color: lastInteractionLabel.labelColor }}
                  >
                    {lastInteractionLabel.labelText}
                  </Badge>
                )}
                {profile === "admin" && (
                  <Tooltip title="Espiar Conversa">
                    <VisibilityIcon
                      onClick={() => setOpenTicketMessageDialog(true)}
                      fontSize="small"
                      style={{
                        color: blue[700],
                        cursor: "pointer",
                        marginLeft: 10,
                        verticalAlign: "middle",
                      }}
                    />
                  </Tooltip>
                )}
                <Box className={classes.ticketInfo1}>{renderTicketInfo()}</Box>
              </Typography>
            </span>
          }
          secondary={
            <span className={classes.contactNameWrapper}>
              <Typography
                className={classes.contactLastMessage}
                noWrap
                component="span"
                variant="body2"
                color="textSecondary"
              >
                {ticket.lastMessage && ticket.lastMessage.match(/data:image\/(png|jpg|jpeg|gif|svg);base64,/) ? (
                  <MarkdownWrapper>Imagem recebida</MarkdownWrapper>
                ) : (
                  <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                )}

                <span className={classes.secondaryContentSecond}>
                  {whatsAppName ? <Badge className={classes.connectionTag}>{whatsAppName}</Badge> : <br></br>}
                  {ticketUser ? <Badge style={{ backgroundColor: "#000000" }} className={classes.connectionTag}>{ticketUser}</Badge> : <br></br>}
                  <Badge style={{ backgroundColor: ticketQueueColor }} className={classes.connectionTag}>{ticketQueueName || "SEM FILA"}</Badge>
                </span>
                <span style={{ paddingTop: "2px" }} className={classes.secondaryContentSecond}>
                  {tag?.map((tag) => (
                    <ContactTag tag={tag} key={`ticket-contact-tag-${ticket.id}-${tag.id}`} />
                  ))}
                </span>
              </Typography>
              <Badge
                className={classes.newMessagesCount}
                badgeContent={ticket.unreadMessages}
                classes={{
                  badge: classes.badgeStyle,
                }}
              />
            </span>
          }
        />
        <Box display="flex" alignItems="center">
          {ticket.status === "pending" && (
            <ButtonWithSpinner
              style={{ backgroundColor: 'green', color: 'white', padding: '0px', bottom: '17px', borderRadius: '0px', left: '8px', fontSize: '0.6rem' }}
              variant="contained"
              className={classes.acceptButton}
              size="small"
              loading={loading}
              onClick={e => handleAcceptTicket(ticket.id)}
            >
              {i18n.t("ticketsList.buttons.accept")}
            </ButtonWithSpinner>
          )}
          {ticket.status !== "closed" && (
            <ButtonWithSpinner
              style={{ backgroundColor: 'red', color: 'white', padding: '0px', bottom: '0px', borderRadius: '0px', left: '8px', fontSize: '0.6rem' }}
              variant="contained"
              className={classes.acceptButton}
              size="small"
              loading={loading}
              onClick={e => handleCloseTicket(ticket.id)}
            >
              {i18n.t("ticketsList.buttons.closed")}
            </ButtonWithSpinner>
          )}
          {ticket.status === "closed" && (
            <ButtonWithSpinner
              style={{ backgroundColor: 'red', color: 'white', padding: '0px', bottom: '0px', borderRadius: '0px', left: '8px', fontSize: '0.6rem' }}
              variant="contained"
              className={classes.acceptButton}
              size="small"
              loading={loading}
              onClick={e => handleReopenTicket(ticket.id)}
            >
              {i18n.t("ticketsList.buttons.reopen")}
            </ButtonWithSpinner>
          )}
        </Box>
        <ListItemSecondaryAction>
          {ticket.lastMessage && (
            <Typography className={classes.lastMessageTime} component="span" variant="body2" color="textSecondary">
              {isSameDay(parseISO(ticket.updatedAt), new Date())
                ? format(parseISO(ticket.updatedAt), "HH:mm")
                : format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}
            </Typography>
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
};

export default TicketListItemCustom;