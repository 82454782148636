import React, { useState, useEffect } from "react";
import { Avatar, CardHeader } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const TicketInfo = ({ contact, ticket, onClick }) => {
	const { user } = ticket;
	const [userName, setUserName] = useState('');
	const [contactName, setContactName] = useState('');
	const [queueName, setQueueName] = useState('');

	useEffect(() => {
		if (contact) {
			setContactName(contact.name);
			if (document.body.offsetWidth < 600) {
				if (contact.name.length > 10) {
					const truncatedName = contact.name.substring(0, 10) + '...';
					setContactName(truncatedName);
				}
			}
		}

		if (user) {
			setUserName(`${i18n.t("messagesList.header.assignedTo")} ${user.name}`);

			if (document.body.offsetWidth < 600) {
				setUserName(`${user.name}`);
			}
		}

		if (ticket.queue && ticket.queue.name) {
			setQueueName(ticket.queue.name);
		}
	}, [contact, user, ticket.queue]);

	return (
		<CardHeader
			onClick={onClick}
			style={{ cursor: "pointer" }}
			titleTypographyProps={{ noWrap: true }}
			subheaderTypographyProps={{ noWrap: true }}
			avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
			title={`${contactName} #${ticket.id}`}
			subheader={ticket.user && `${userName} - Setor: ${queueName}`}
		/>
	);
};

export default TicketInfo;