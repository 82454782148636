import React, { useState, useCallback } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import brLocale from "date-fns/locale/pt-BR";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Button, Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import api from "../../services/api";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "./button.css";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
    },
    datePicker: {
        width: "20ch",
    },
    button: {
        backgroundColor: "#2DDD7F",
        "&:hover": {
            backgroundColor: "#1CBF67",
        },
    },
    chartContainer: {
        maxWidth: "100%",
        maxHeight: "280px",
    }
}));

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top",
            display: false,
        },
        title: {
            display: true,
            text: "Gráfico de Conversas",
            position: "left",
        },
        datalabels: {
            display: true,
            anchor: "start",
            offset: -30,
            align: "start",
            color: "#fff",
            textStrokeColor: "#000",
            textStrokeWidth: 2,
            font: {
                size: 20,
                weight: "bold"
            },
        }
    },
};

export const ChatsUser = () => {
    const classes = useStyles();
    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());
    const [ticketsData, setTicketsData] = useState({ data: [] }); // Estado inicial vazio

    const companyId = localStorage.getItem("companyId");

    // Função para buscar os dados de tickets
    const handleGetTicketsInformation = useCallback(async () => {
        if (!companyId) {
            toast.error("ID da empresa não encontrado.");
            return;
        }

        try {
            const { data } = await api.get(`/dashboard/ticketsUsers?initialDate=${format(initialDate, "yyyy-MM-dd")}&finalDate=${format(finalDate, "yyyy-MM-dd")}&companyId=${companyId}`);
            setTicketsData(data);
        } catch (error) {
            toast.error("Erro ao obter informações da conversa");
        }
    }, [initialDate, finalDate, companyId]);

    // Estrutura do gráfico, mesmo que esteja vazio inicialmente
    const dataCharts = {
        labels: ticketsData.data.length > 0 ? ticketsData.data.map((item) => item.nome) : ['Sem dados'],
        datasets: [
            {
                data: ticketsData.data.length > 0 ? ticketsData.data.map((item) => item.quantidade) : [0],
                backgroundColor: ticketsData.data.length > 0 ? "#2DDD7F" : "#ccc",
            },
        ],
    };

    return (
        <div className={classes.container}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Total de Conversas por Usuários
            </Typography>

            <Stack direction="row" spacing={2} alignItems="center" sx={{ my: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                    <DatePicker
                        value={initialDate}
                        onChange={(newValue) => newValue && setInitialDate(newValue)}
                        label="Início"
                        renderInput={(params) => <TextField {...params} className={classes.datePicker} />}
                    />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                    <DatePicker
                        value={finalDate}
                        onChange={(newValue) => newValue && setFinalDate(newValue)}
                        label="Fim"
                        renderInput={(params) => <TextField {...params} className={classes.datePicker} />}
                    />
                </LocalizationProvider>

                <Button className={`${classes.button} buttonHover`} onClick={handleGetTicketsInformation} variant="contained">
                    Filtrar
                </Button>
            </Stack>
            <div className={classes.chartContainer}>
                <Bar options={options} data={dataCharts} />
            </div>
        </div>
    );
};