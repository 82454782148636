const checkoutFormModel = {
  formId: 'checkoutForm',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'Nome completo*',
      requiredErrorMsg: 'O nome completo é obrigatório'
    },
    lastName: {
      name: 'lastName',
      label: 'Sobrenome*',
      requiredErrorMsg: 'O sobrenome é obrigatório'
    },
    address1: {
      name: 'address1',
      label: 'Endereço*',
      requiredErrorMsg: 'O Endereço é obrigatório' // Corrigido de 'address2' para 'address1'
    },
    city: {
      name: 'city',
      label: 'Cidade*',
      requiredErrorMsg: 'Cidade é obrigatória'
    },
    state: {
      name: 'state',
      label: 'Estado*',
      requiredErrorMsg: 'O Estado é obrigatório' // Corrigida a mensagem de erro
    },
    zipcode: {
      name: 'zipcode',
      label: 'CEP*',
      requiredErrorMsg: 'CEP é obrigatório',
      invalidErrorMsg: 'Formato de CEP inválido'
    },
    country: {
      name: 'country',
      label: 'País*',
      requiredErrorMsg: 'País é obrigatório'
    },
    useAddressForPaymentDetails: {
      name: 'useAddressForPaymentDetails',
      label: 'Use este endereço para detalhes de pagamento'
    },
    invoiceId: {
      name: 'invoiceId',
      label: 'Use este ID de fatura'
    },
    nameOnCard: {
      name: 'nameOnCard',
      label: 'Nome no cartão*',
      requiredErrorMsg: 'O nome no cartão é obrigatório'
    },
    cardNumber: {
      name: 'cardNumber',
      label: 'Número do cartão*',
      requiredErrorMsg: 'O número do cartão é obrigatório',
      invalidErrorMsg: 'O número do cartão não é válido (ex: 4111111111111)'
    },
    expiryDate: {
      name: 'expiryDate',
      label: 'Data de validade*',
      requiredErrorMsg: 'A data de validade é obrigatória',
      invalidErrorMsg: 'A data de validade não é válida'
    },
    cvv: {
      name: 'cvv',
      label: 'CVV*',
      requiredErrorMsg: 'O CVV é obrigatório',
      invalidErrorMsg: 'O CVV não é válido (ex: 357)'
    }
  }
};

export default checkoutFormModel;