import React, { useEffect, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, ListItemText } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const localStorageKey = 'selectedQueueIds';
const TicketsQueueSelect = ({
	userQueues,
	selectedQueueIds = [],
	onChange,
}) => {
	const [initialLoad, setInitialLoad] = useState(true);

	// Carregar seleções do localStorage quando o componente for montado (seleção de fina no listbox)
	useEffect(() => {
		if (initialLoad) {
			const savedSelectedQueueIds = JSON.parse(localStorage.getItem(localStorageKey));
			if (savedSelectedQueueIds) {
				onChange(savedSelectedQueueIds);
			}
			setInitialLoad(false);
		}
	}, [initialLoad, onChange]);

	const handleChange = (e) => {
		const newSelectedQueueIds = e.target.value;
		onChange(newSelectedQueueIds);
		localStorage.setItem(localStorageKey, JSON.stringify(newSelectedQueueIds));
	};

	return (
		<div style={{ width: 120, marginTop: -4 }}>
			<FormControl fullWidth margin="dense">
				<Select
					multiple
					displayEmpty
					variant="outlined"
					value={selectedQueueIds}
					onChange={handleChange}
					id="tickets-queue-select" // Adicionado id
					name="ticketsQueueSelect" // Adicionado name
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}
					renderValue={() => i18n.t("ticketsQueueSelect.placeholder")}
				>
					{userQueues?.length > 0 && userQueues.map((queue) => (
						<MenuItem dense key={queue.id} value={queue.id}>
							<Checkbox
								style={{ color: queue.color }}
								size="small"
								color="primary"
								checked={selectedQueueIds.includes(queue.id)}
								id={`checkbox-${queue.id}`} // Adicionado id
								name={`checkbox-${queue.id}`} // Adicionado name
							/>
							<ListItemText primary={queue.name} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default TicketsQueueSelect;