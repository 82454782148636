import React from "react";

/**
 * ColorModeContext é um contexto do React usado para gerenciar o modo de cor (por exemplo, claro/escuro)
 * em uma aplicação. 
 * 
 * Este contexto deve ser utilizado em conjunto com o ColorModeContext.Provider para fornecer 
 * a função `toggleColorMode` aos componentes que precisam alterar o modo de cor.
 * 
 * @example
 * // No arquivo do provedor (ex: App.js)
 * <ColorModeContext.Provider value={{ toggleColorMode: suaFuncaoToggle }}>
 *   <SeuComponente />
 * </ColorModeContext.Provider>
 * 
 * // No componente consumidor
 * const { toggleColorMode } = React.useContext(ColorModeContext);
 * toggleColorMode(); // Altera o modo de cor
 * 
 * Nota: Se `toggleColorMode` for chamado fora de um provedor, uma mensagem de aviso será exibida no console.
 */
const ColorModeContext = React.createContext({
    toggleColorMode: () => {
        console.warn("toggleColorMode não foi definido. Certifique-se de usar ColorModeContext.Provider para fornecer o valor.");
    }
});

export default ColorModeContext;