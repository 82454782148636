import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Button, Divider } from "@material-ui/core";
import NewTicketModal from "../NewTicketModal";

const VCardPreview = ({ contact, numbers }) => {
    const history = useHistory();
    const { user } = useContext(AuthContext);

    const [selectedContact, setContact] = useState({
        id: "",
        name: "",
        number: "",
        profilePicUrl: ""

    });

    const [selectedQueue, setSelectedQueue] = useState("");
    const [isModalOpen, setModalOpen] = useState(false);
    const [isContactValid, setContactValid] = useState(true);
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [contactTicket, setContactTicket] = useState({});

    const handleQueueSelection = async (queueId) => {
        setSelectedQueue(queueId);
        setModalOpen(false);
        if (queueId !== "") {
            await createTicket(queueId);
        }
    };

    const renderQueueModal = () => (
        <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
            <div style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "#FFF",
                padding: "20px",
                outline: "none",
            }}>
                <h2>Selecione a Fila</h2>
                {user.queues.map((queue) => (
                    <div key={queue.id}>
                        <Button onClick={() => handleQueueSelection(queue.id)}>
                            {queue.name}
                        </Button>
                    </div>
                ))}
            </div>
        </Modal>
    );

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const formattedNumber = numbers.replace(/\D/g, "");
                // Busca o contato pelo número
                const { data } = await api.get(`/contacts?number=${formattedNumber}`);

                if (data && data.count > 0) {
                    // Filtra os contatos que correspondem exatamente ao número formatado
                    const filteredContacts = data.contacts.filter(contact => contact.number === formattedNumber);

                    if (filteredContacts.length > 0) {
                        // Se o contato filtrado existir, seta o contato encontrado
                        setContact(filteredContacts[0]);
                    } else {
                        // Caso não encontre nenhum contato com o número específico
                        setContact({
                            name: contact || "Nome Desconhecido", // Atribui nome padrão caso o nome esteja vazio
                            number: formattedNumber,
                            profilePicUrl: "",
                        });
                    }
                } else {
                    // Se não existe, cria um objeto de contato para cadastro
                    setContact({
                        name: contact || "Nome Desconhecido", // Atribui nome padrão caso o nome esteja vazio
                        number: formattedNumber,
                        profilePicUrl: "",
                    });
                }

                // Verifica se o contato é válido (pode adicionar mais lógica aqui, se necessário)
                setContactValid(true);

            } catch (err) {
                toastError(err);
            }
        };

        // Debounce para evitar muitas requisições
        const delayDebounceFn = setTimeout(fetchContacts, 1500);
        return () => clearTimeout(delayDebounceFn);
    }, [contact, numbers]);

    const handleNewChat = async () => {
        try {
            let contactId = selectedContact.id;

            if (!contactId) {
                // Se o nome do contato estiver vazio, atribui um nome padrão
                const contactName = selectedContact.name && selectedContact.name.trim() !== ""
                    ? selectedContact.name
                    : "Nome Desconhecido";

                const { data } = await api.post("/contacts", {
                    name: contactName,
                    number: selectedContact.number,
                    email: ""
                });
                contactId = data.id; // Define o ID do novo contato
                setContact(data);
            }

            if (selectedQueue) {
                await createTicket(selectedQueue);
            } else {
                setContactTicket({...selectedContact, id: contactId}); // Define o contato para o modal
                setNewTicketModalOpen(true); // Abre o modal para novo ticket
            }
        } catch (err) {
            toastError(err);
        }
    };

    const createTicket = async (queueId) => {
        try {
            let contactId = selectedContact.id;

            if (!contactId) {
                const contactObj = {
                    name: selectedContact.name || "Nome Desconhecido", // Garante que o nome será preenchido
                    number: selectedContact.number,
                    email: ""
                };

                const { data } = await api.post("/contacts", contactObj);
                contactId = data.existingContact.id;
            }

            const { ticket } = await api.post("/tickets", {
                contactId,
                queueId,
                userId: user.id,
                status: "open",
            });

            history.push(`/tickets/${ticket.uuid}`);
        } catch (err) {
            toastError(err);
        }
    };

    const handleCloseOrOpenTicket = (ticket) => {
        setNewTicketModalOpen(false);
        if (ticket && ticket.uuid) {
            history.push(`/tickets/${ticket.uuid}`);
        }
    };

    return (
        <>
            {renderQueueModal()}
            <div style={{ minWidth: "250px", padding: "8px" }}>
                <NewTicketModal
                    modalOpen={newTicketModalOpen}
                    initialContact={contactTicket}
                    onClose={handleCloseOrOpenTicket}
                />
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4} md={3}>
                        <Avatar
                            src={selectedContact.profilePicUrl}
                            alt={selectedContact.name}
                            style={{ width: 70, height: 70 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={9} md={11}>
                        <Typography variant="h6" color="textPrimary">
                            {selectedContact.name}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            <strong>Telefone:</strong> {selectedContact.number}
                        </Typography>
                        <Divider style={{ margin: "4px 0" }} />
                        <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            onClick={handleNewChat} // Chamando handleNewChat
                            disabled={!selectedContact.number || !isContactValid}
                        >
                            {isContactValid ? "Conversar (Novo Ticket)" : "CONTATO FORA DO WHATSAPP"}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default VCardPreview;